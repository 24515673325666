<template>
	<div class="header">Edit</div>
	<div class="body">
		<input type="text" v-model="value" class="hFill ba" />
	</div>
	<div class="footer">
		<button @click="$emit('close')">Close</button>
		<button @click="$emit('close', value)" class="bgGrdPos">OK</button>
	</div>
</template>

<script>
	import { ref } from "vue"

	export default {
		name: "Edit",
		props: {
			text: { type: String, default: "Untitled" }
		},
		emits: ["close"],
		setup(props) {
			const value = ref(props.text)
			return { value }
		}
	}
</script>
