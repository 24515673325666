<template>
	<div class="tags">
		<div class="flx gap-2">
			<Richbutton theme="pos" @click="create">New Tag</Richbutton>
			<input type="search" v-model="search" placeholder="Filter Tags..." class="search flx-1 ba" />
		</div>
		<div class="results">
			<div v-if="tags.length">Right-click to edit</div>

			<div
				v-for="(tag, indx) in tags"
				:key="tag.id"
				class="searchtag"
				@click="(e) => onClick(e, tag)"
				@contextmenu.prevent="(e) => onClick(e, tag)"
			>
				<span>{{ tag.title }}</span>
				<button class="x ml-a" @click="destroy(tag.id, indx)" />
			</div>
		</div>
	</div>
</template>

<script>
	import { debounce } from "lodash"
	import { ref, computed, watch } from "vue"
	import { api, alertsList, modals } from "@/store"
	import { Richbutton } from "@/components"
	import Edit from "./Edit"

	export default {
		name: "Tags",
		components: { Richbutton },
		props: {},
		setup(props, ctx) {
			const modalStore = modals()
			const alerts = alertsList()

			const tags = ref([])
			const search = ref("")
			const fetchTags = debounce(() => {
				if (search.value) {
					const qs = { search: search.value }
					api.get("admin/exercises/tags", { qs }).then((res) => {
						tags.value = res.items
					})
				} else {
					tags.value = []
				}
			}, 500)
			watch(search, fetchTags)

			const update = async (tag, title) => {
				await api.put(`admin/exercises/tags/${tag.id}`, { json: { title } })
				tag.title = title
			}

			const create = async () => {
				const title = await modals().open.component({
					component: Edit,
					size: "sm"
				})
				if (title) {
					const tag = await api.post(`admin/exercises/tags`, { json: { title } })
					tags.value.unshift(tag)
				}
			}

			const destroy = (tagId, indx) => {
				api.del(`admin/exercises/tags/${tagId}`)
					.then(() => tags.value.splice(indx, 1))
					.catch(() => alerts.push("Cannot delete tag", "neg"))
			}

			const onClick = async (e, tag) => {
				if (e.which === 3) {
					const title = await modals().open.component({
						component: Edit,
						props: { text: tag.title },
						size: "sm"
					})
					if (title) return update(tag, title)
				} else {
					ctx.emit("select", tag)
				}
			}

			return {
				search,
				tags,
				create,
				destroy,
				onClick
			}
		}
	}
</script>

<style lang="scss">
	.tags {
		display: grid;
		grid-template-rows: auto 1fr;
		grid-gap: $atom * 5;

		.results {
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
			grid-gap: $atom;
			.searchtag {
				font-size: $fnt-sml;
				min-height: $ctrl-ht;
				background: #fff;
				border-radius: 3px;
				display: flex;
				align-items: center;
				padding: 0 $atom * 5;
				.x {
					opacity: 0;
				}
				&:hover .x {
					opacity: 1;
				}
			}
		}
	}
</style>
