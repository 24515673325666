<template>
	<div class="tagsWrapper mh-5 mt-5">
		<Tags />
	</div>
</template>

<script>
	import Tags from "./Tags"

	export default {
		name: "TagsAdmin",
		components: { Tags }
	}
</script>

<style lang="scss"></style>
